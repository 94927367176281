import React from 'react';
import { Button, Checkbox, Stack, TableCellProps, TableHead, Toolbar, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { ProductStatusType } from '../../pages/Products/ProductsList/ProductsListPage';
import { useProductActions } from '../../recoil/products/useProduct';
import TablePaginationCustom from './TablePaginationCustom';

export interface ITableColumnTypes {
  id: string;
  alignCol?: string;
  label: string;
  display?: string;
  minWidth?: number;
  maxWidth?: number;
}

interface ICustomDataTableProps {
  activeTab?: ProductStatusType;
  checkedItemList?: any[];
  onAllItemClick?: () => void;
  onOpenConfirmDialog?: () => void;
  onOpenDeleteDialog?: () => void;
  tableColumn: Array<ITableColumnTypes>;
  // pagination
  rowsPerPage?: number;
  page?: number;
  totalElement?: number;
  dense?: boolean;
  onChangePage?: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //
  children: React.ReactNode;
  sx?: any;
  //
  columCheck?: boolean;
}

export default function CustomDataTable({
  activeTab,
  checkedItemList,
  onAllItemClick,
  tableColumn,
  onOpenConfirmDialog,
  onOpenDeleteDialog, // pagination
  rowsPerPage,
  page,
  totalElement,
  dense,
  onChangePage,
  onChangeRowsPerPage,
  onChangeDense,
  children,
  sx,
  // 기존코드 때문에 사용
  columCheck,
}: Readonly<ICustomDataTableProps>) {
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page && rowsPerPage && rowsLength && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsLength) : 0;
  // const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
  //   setPage(newPage);
  // };

  return (
    <Stack sx={{ ...sx }}>
      {/* Checkbox */}
      {activeTab && onOpenConfirmDialog && onOpenDeleteDialog && checkedItemList && (
        <EnhancedTableToolbar
          checkedItemList={checkedItemList}
          activeTab={activeTab}
          onOpenConfirmDialog={onOpenConfirmDialog}
          onOpenDeleteDialog={onOpenDeleteDialog}
        />
      )}

      <TableContainer
        sx={{
          pt: '0 !important',
          maxHeight: '652px',
          overflowY: 'auto',
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <Table
          stickyHeader
          aria-label="custom pagination table"
          sx={{
            position: 'relative',
            borderCollapse: 'separate !important',
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  padding: '16px',
                  border: 0,
                  color: 'text.secondary',
                  background: 'background.neutral',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  boxSizing: 'border-box',
                  // '&:first-of-type': {
                  //   paddingLeft: '24px',
                  // },
                  // '&:last-child': {
                  //   paddingRight: '24px',
                  // },
                },
              }}
            >
              {checkedItemList && onAllItemClick && (
                <TableCell align={'right'}>
                  <Checkbox
                    onClick={onAllItemClick}
                    checked={!!checkedItemList.length && checkedItemList.length === rowsPerPage}
                  />
                </TableCell>
              )}
              {/*{<Switch checked={false} onChange={(e) => {}} />}*/}

              {tableColumn.map((item) => {
                return (
                  <TableCell
                    key={item.id}
                    style={{ display: item?.display }}
                    align={item?.alignCol as TableCellProps['align']}
                    sx={{ minWidth: item?.minWidth, maxWidth: item?.maxWidth }}
                  >
                    {item.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              overflowY: 'auto',
              overflowX: 'auto',
              '& tr': {
                '& td': {
                  borderBottom: 0,
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '22px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  // '&:first-of-type': {
                  //   paddingLeft: '24px',
                  // },
                  // '&:last-child': {
                  //   paddingRight: '24px',
                  // },
                },
              },
            }}
          >
            {children}
            {/*{emptyRows > 0 && (*/}
            {/*  <TableRow style={{ height: 54 * emptyRows }}>*/}
            {/*    <TableCell colSpan={tableColumn.length} />*/}
            {/*  </TableRow>*/}
            {/*)}*/}
          </TableBody>
        </Table>
      </TableContainer>
      {/*TO DO: pagination prop optional 풀면 조건 없앨 예정*/}
      <TablePaginationCustom
        count={totalElement ?? 0}
        page={!totalElement || !page ? 0 : page}
        rowsPerPage={rowsPerPage ?? 10}
        onPageChange={onChangePage ?? ((event, newPage) => {})}
        onRowsPerPageChange={onChangeRowsPerPage}
        dense={dense}
        onChangeDense={onChangeDense}
      />
    </Stack>
  );
}

const EnhancedTableToolbar = ({
  activeTab,
  onOpenConfirmDialog,
  onOpenDeleteDialog,
  checkedItemList,
}: {
  activeTab: ProductStatusType;
  onOpenConfirmDialog: () => void;
  onOpenDeleteDialog: () => void;
  checkedItemList?: any[];
}) => {
  const { useProductItemListDelete } = useProductActions();
  const { mutateAsync: deleteProductItems } = useProductItemListDelete();
  const numSelected = checkedItemList?.length || 0;
  return (
    // <AppBar position="static">
    <Toolbar
      sx={{
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
        <Typography variant="subtitle2">{numSelected} product selected</Typography>
        <Stack direction="row" gap="17px">
          <Button
            variant="contained"
            color="error"
            size="medium"
            disabled={numSelected === 0}
            onClick={async () => {
              // await deleteProductItems(checkedItemList?.map((item) => item.id) ?? []);
              onOpenDeleteDialog();
            }}
            sx={{
              minWidth: '68px',
              fontSize: '14px',
              lineHeight: '24px',
              px: '12px !important',
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            size="medium"
            disabled={numSelected === 0}
            onClick={onOpenConfirmDialog}
            sx={{
              minWidth: activeTab === 'DRAFTED' ? '68px' : '113px',
              fontSize: '14px',
              lineHeight: '24px',
              px: '12px !important',
            }}
          >
            {activeTab === 'DRAFTED' ? 'Publish' : 'Move to draft'}
          </Button>
        </Stack>
      </Stack>
    </Toolbar>
    // </AppBar>
  );
};
