import { useEffect, useMemo } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { RemakePrice, RemakePriceRequest, UpdateProductRemakePriceRequest } from '../../../client';
import FormProvider from '../../../components/RHF/FormProvider';
import RHFRadioGroup from '../../../components/RHF/RHFRadioGroup';
import RHFTextField from '../../../components/RHF/RHFTextField';
import { ICloseButtonOnlyDialogueProps } from '../../../constants/helperHooks/useDialogue';
import { handleDecimalNumberInputValidate } from '../../../utils/inputHandler';
import { RemakeCostFormType } from './RemakeCostFormType';

type SetupRemakeDialogueProps = ICloseButtonOnlyDialogueProps & {
  selectedProductForRemake: RemakePriceRequest;
  modifiedProductRemakePrice: UpdateProductRemakePriceRequest;
  setModifiedProductRemakePrice: any;
};

export const DiscountType = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
} as const;

const remakeCostMethodOption = {
  [DiscountType.AMOUNT]: { value: 'amount', label: 'Amount (JPY)' },
  [DiscountType.PERCENTAGE]: { value: 'percentage', label: 'Percentage (%)' },
};

export const SetupRemakeDialogue = ({
  open,
  onClose,
  selectedProductForRemake,
  modifiedProductRemakePrice,
  setModifiedProductRemakePrice,
}: SetupRemakeDialogueProps) => {
  const { productId: selectedProductId, remakePrice: selectedRemakePrice } = selectedProductForRemake;
  const isAllRemake = !selectedRemakePrice || !selectedProductId;

  const defaultRemakeCosts = useMemo(() => {
    return isAllRemake
      ? Array.from({ length: 5 }, (_, i) => createRemakeCost(i + 1, DiscountType.PERCENTAGE, 100))
      : selectedRemakePrice.map((price) =>
          createRemakeCost(price.stageNumber, price.discountType, price.discountAmount),
        );
  }, [isAllRemake, selectedRemakePrice]);
  const defaultRemakeCostMethod = useMemo(
    () =>
      selectedRemakePrice?.length > 0 ? remakeCostMethodOption[selectedRemakePrice[0].discountType].value : 'amount',
    [selectedRemakePrice],
  );
  const REMAKE_COST_FORM_DEFAULT_VALUE = {
    remakeCostMethod: defaultRemakeCostMethod,
    remakeCosts: defaultRemakeCosts,
  };

  const methods = useForm<RemakeCostFormType>({
    mode: 'onSubmit',
    defaultValues: REMAKE_COST_FORM_DEFAULT_VALUE,
  });
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    trigger,
    formState: { isDirty, isValid },
  } = methods;

  const remakeCostMethod = watch('remakeCostMethod');
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.target.name as FieldPath<RemakeCostFormType>;
    let newValue = watch(name);
    if (newValue === '' || !newValue) {
      newValue = 0;
    } else {
      newValue = Math.round(Number(newValue));
      if (remakeCostMethod !== 'amount' && newValue > 100) {
        newValue = 100;
      }
    }
    setValue(name, newValue);
    trigger(name);
  };

  const onSubmit = (data: RemakeCostFormType) => {
    const updatedFormData: RemakePrice[] = data.remakeCosts.map((cost) => ({
      stageNumber: cost.stageNumber,
      discountAmount: data.remakeCostMethod === 'amount' ? cost.amount : cost.percentage,
      discountType: data.remakeCostMethod === 'amount' ? DiscountType.AMOUNT : DiscountType.PERCENTAGE,
    }));
    setModifiedProductRemakePrice((prev: UpdateProductRemakePriceRequest) => {
      return isAllRemake
        ? { updateAllInfo: updatedFormData, updateSingle: [] }
        : (() => {
            const existingProductIndex = prev.updateSingle.findIndex(
              (item: RemakePriceRequest) => item.productId === selectedProductId,
            );
            return {
              ...prev,
              updateSingle:
                existingProductIndex !== -1
                  ? prev.updateSingle.map((item, index) =>
                      index === existingProductIndex
                        ? { productId: selectedProductId, remakePrice: updatedFormData }
                        : item,
                    )
                  : [...prev.updateSingle, { productId: selectedProductId, remakePrice: updatedFormData }],
            };
          })();
    });
    onClose();
  };

  const effectiveRemakePrice = useMemo(() => {
    if (isAllRemake) {
      return modifiedProductRemakePrice.updateAllInfo;
    } else {
      const singleRemakePrice =
        modifiedProductRemakePrice.updateSingle &&
        modifiedProductRemakePrice.updateSingle.find((item) => item.productId === selectedProductId);
      if (singleRemakePrice) {
        return singleRemakePrice.remakePrice;
      } else if (modifiedProductRemakePrice.updateAllInfo.length > 0) {
        return modifiedProductRemakePrice.updateAllInfo;
      } else {
        return [];
      }
    }
  }, [isAllRemake, selectedProductForRemake, modifiedProductRemakePrice]);

  useEffect(() => {
    if (effectiveRemakePrice.length > 0) {
      const isAmountType = effectiveRemakePrice[0].discountType === DiscountType.AMOUNT;
      const remakeCostsForDefaultReset = effectiveRemakePrice.map((price) =>
        createRemakeCost(price.stageNumber, price.discountType, price.discountAmount),
      );

      reset({
        remakeCostMethod: isAmountType ? 'amount' : 'percentage',
        remakeCosts: remakeCostsForDefaultReset,
      });
    } else {
      reset(REMAKE_COST_FORM_DEFAULT_VALUE);
    }
  }, [open, reset, effectiveRemakePrice]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '455px !important',
          minWidth: '455px',
        },
      }}
    >
      <DialogTitle>{`Setup remake for ${isAllRemake ? 'all' : 'selected'}`}</DialogTitle>
      <DialogContent sx={{ paddingBottom: '24px' }}>
        <FormProvider id={'setupRemakeDialogue'} methods={methods}>
          <Stack gap="16px">
            {isAllRemake && (
              <Typography variant="body2" color="text.secondary">
                This setup will reflect all the products.
              </Typography>
            )}
            <Stack gap="8px">
              <Typography variant="subtitle1">Remake cost</Typography>
              <Stack direction="row">
                <RHFRadioGroup name="remakeCostMethod" options={Object.values(remakeCostMethodOption)} row={true} />
              </Stack>
              <Stack gap="16px">
                {watch('remakeCosts').map((cost, index) => {
                  const fieldName =
                    `remakeCosts.${index}.${remakeCostMethod === 'amount' ? 'amount' : 'percentage'}` as FieldPath<RemakeCostFormType>;
                  return (
                    <Stack gap="8px" key={cost.stageNumber} sx={{ paddingLeft: '8px' }}>
                      <Typography variant="subtitle1">{`${getOrdinalSuffix(cost.stageNumber)} remake cost`}</Typography>
                      <RHFTextField
                        name={fieldName}
                        label={''}
                        variant="outlined"
                        size="small"
                        value={remakeCostMethod === 'amount' ? cost.amount : cost.percentage}
                        isNumberField={true}
                        onBlur={onBlur}
                        inputProps={{
                          onInput: handleDecimalNumberInputValidate,
                          inputMode: 'decimal',
                        }}
                        InputProps={{
                          startAdornment: (
                            <Typography variant="body2" color="text.disabled" sx={{ paddingRight: '9px' }}>
                              {remakeCostMethod === 'amount' ? 'JPY' : '%'}
                            </Typography>
                          ),
                        }}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{ border: 'none !important' }}>
        <Button variant="outlined" color="inherit" size="medium" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const createRemakeCost = (stageNumber: number, discountType: keyof typeof DiscountType, discountAmount: number) => ({
  stageNumber,
  amount: discountType === DiscountType.AMOUNT ? discountAmount : 0,
  percentage: discountType === DiscountType.PERCENTAGE ? discountAmount : 100,
});

const getOrdinalSuffix = (num: number): string => {
  const ordinals: { [key: number]: string } = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    4: 'th',
    5: 'th',
  };
  return `${num}${ordinals[num] || ''}`;
};
