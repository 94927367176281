import * as React from 'react';
import { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { ProductDetail } from '../../../client';
import CustomAlertDialog from '../../../components/dialog/CustomAlertDialog';
import Iconify from '../../../components/iconify/Iconify';
import MenuPopover from '../../../components/popover/MenuPopover';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useProductActions } from '../../../recoil/products/useProduct';
import AddProductDialog from './AddProductDialog';

interface Iprops {
  row: ProductDetail;
  isDraftTab: boolean;
}
export default function TableRowActions({ row, isDraftTab }: Iprops) {
  const { useProductItemDelete } = useProductActions();
  const { mutateAsync: deleteProductItem } = useProductItemDelete();

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const { productDeleteById, getAllProductsForListing } = useProductActions();
  const [openMenu, setOpenMenu] = useState<HTMLElement | null>(null);
  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();
  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          setOpenMenu(e.currentTarget);
        }}
      >
        <Iconify icon={'eva:more-vertical-fill'} sx={{ color: 'action.active', width: 20, height: 20 }} />
      </IconButton>
      <MenuPopover id="popover-product-edit" open={openMenu} onClose={handleCloseMenu} sx={{ minWidth: 200 }}>
        {isDraftTab && (
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              handleOpenDialogue();
            }}
          >
            <Typography variant="body2">Edit</Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setOpenAlertDialog(true);
            // getProductForDeleteHandler(row.id)
          }}
        >
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </MenuPopover>

      {openAlertDialog && (
        <CustomAlertDialog
          title={`Delete Product?`}
          desc={`Are you sure to delete this product?`}
          alertType={'danger'}
          btnActionText={'Delete'}
          btnAction={async () => {
            await deleteProductItem(row.id);
            handleCloseMenu();
          }}
          open={openAlertDialog}
          handleClose={() => {
            handleCloseMenu();
            setOpenAlertDialog(false);
          }}
        />
      )}
      {open && (
        <AddProductDialog
          open={open}
          onCloseDialog={handleCloseDialogue}
          btnActionText={'Confirm'}
          btnAction={() => {
            console.log('');
          }}
          productForEdit={row}
        />
      )}
    </>
  );
}
