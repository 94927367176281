import React, { useState } from 'react';
import { Card, Stack, Typography } from '@mui/material';

import { ReactComponent as IconWarning } from '../../../assets/images/icon-warning-circle.svg';
import { ProductDetail } from '../../../client';
import CustomAlertDialog from '../../../components/dialog/CustomAlertDialog';
import useTable from '../../../components/table/useTable';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useProductActions } from '../../../recoil/products/useProduct';
import ActiveTab from './ActiveTab';
import AddProductDialog from './AddProductDialog';
import ProductsTable from './ProductListTable';
import { ProductsTableToolbar } from './TableToolbar';

export type ProductStatusType = ProductDetail['status'];

// Add, Edit Product dialog 관련
const ProductsListPage = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0, defaultOrderBy: 'id', defaultOrder: 'desc' });
  const { dense, page, order, orderBy, rowsPerPage, onChangeDense, onChangePage, onChangeRowsPerPage } = table;
  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();
  const [openAddEditDlg, setOpenAddEditDlg] = React.useState(false);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);

  const { useAllProductList } = useProductActions();
  const [activeTab, setActiveTab] = React.useState<ProductStatusType>('PUBLISHED');
  const { data, isPending } = useAllProductList({
    status: activeTab,
    page: page + 1,
    size: rowsPerPage,
    sort: [order, orderBy],
  });
  const productList = data?.data?.content ?? [];
  const handleTabChange = (_e: React.SyntheticEvent, newValue: ProductStatusType) => {
    table.setPage(0);
    setActiveTab(newValue);
  };

  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Product List</Typography>
      <Card>
        <ActiveTab activeTab={activeTab} onTabChange={handleTabChange}>
          {activeTab === 'DRAFTED' && (
            <ProductsTableToolbar
              onOpenProductCreateDialogue={handleOpenDialogue}
              setOpenAddEditDlg={() => {}}
              productList={[]}
            />
          )}
          <ProductsTable
            page={page}
            dense={dense}
            totalElement={data?.data?.totalElements as number}
            activeTab={activeTab}
            productList={productList}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangeDense={onChangeDense}
            onChangePage={onChangePage}
            setProductList={() => {}}
            isPending={isPending}
          />
        </ActiveTab>
      </Card>

      <AddProductDialog
        open={open}
        onCloseDialog={handleCloseDialogue}
        btnActionText={'Confirm'}
        btnAction={() => setOpenAlertDialog(true)}
      />

      <CustomAlertDialog
        customActionBtn
        open={isDuplicate}
        dialogActions={false}
        btnAction={() => setIsDuplicate(false)}
        btnActionText="Confirm"
        desc={
          <Typography
            display="flex"
            justifyContent="center"
            fontSize={16}
            fontFamily="Noto Sans, sans-sarif !important"
            color="black"
            textAlign="center"
          >
            <IconWarning style={{ marginRight: 10 }} /> There is same product exists.
          </Typography>
        }
        handleClose={() => setOpenAlertDialog(false)}
        sx={{
          background: '#D9D9D9',
          color: 'black',
          border: '1px solid black',
          width: '150px !important',
          height: '50px !important',
          borderRadius: '0 !important',
        }}
      />
      {/*<CustomAlertLeaveSite blocker={blocker} />*/}
    </Stack>
  );
};

export default ProductsListPage;
