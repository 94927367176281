import React, { useState } from 'react';
import { Box, Button, MenuItem, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { FormikProps } from 'formik';
import humanizeString from 'humanize-string';

import { ColorPair, optionGroup, ProductDetail, ProductOption } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import Iconify from '../../../components/iconify/Iconify';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useProductActions } from '../../../recoil/products/useProduct';
import { NEW_PRODUCT_INIT_STATE } from './AddProductDialog';
import ColorSelector, { DEFAULT_COLOR_SET } from './ColorDialog';
import EditOptionDialog from './EditOptionDialog';

const SELECT_DESIGN_MANUFACTURE_COMBINATION_OPTION = [
  { label: 'Design only', value: 'DESIGN', id: '1' },
  { label: 'Design & Manufacture', value: 'DESIGN_AND_MANUFACTURE', id: '2' },
  { label: 'Manufacture only', value: 'MANUFACTURE', id: '3' },
];
interface SelectDto {
  value: string;
  label: string;
  id: string;
}

interface Iprops {
  onClose: () => void;
  formik: FormikProps<typeof NEW_PRODUCT_INIT_STATE>;
  productForEdit?: ProductDetail;
}
export const AddProductDialogContent = ({ formik, onClose, productForEdit }: Iprops) => {
  const { values, errors, handleChange, handleBlur, touched, setFieldValue } = formik;

  const { useAllProductOptions } = useProductActions();
  const {
    queryLoading,
    prosthesisOption,
    methodOption,
    materialOption,
    shapeOption,
    // priceUnitOption,
    // colorCodeOption,
  } = useAllProductOptions();

  console.log(productForEdit);

  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();
  const [selectedEditOption, setSelectedEditOption] = useState<optionGroup>('PROSTHESIS');

  // color
  const [selectedColor, setSelectedColor] = useState<ColorPair>(DEFAULT_COLOR_SET);
  const handleColorChange = (color: ColorPair) => {
    setSelectedColor(color);
    setFieldValue('stroke', color.stroke);
    setFieldValue('fill', color.fill);
  };
  console.log(selectedColor);

  const isEditMode = !!productForEdit;
  const {
    open: isProsthesisColorDialogOpen,
    handleOpenDialogue: openProsthesisColorDialog,
    handleCloseDialogue: closeProsthesisColorDialog,
  } = useDialogue();
  const getUserSelectedEditOptionList = () => {
    if (selectedEditOption === 'PROSTHESIS') {
      return prosthesisOption;
    } else if (selectedEditOption === 'METHOD') {
      return methodOption;
    } else if (selectedEditOption === 'MATERIAL') {
      return materialOption;
    } else if (selectedEditOption === 'SHAPE') {
      return shapeOption;
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    optionList: Array<ProductOption>,
  ) => {
    const selectedOption = optionList?.find((item) => item.id === e.target.value);
    if (fieldName === 'prosthesis') {
      const formatted = {
        optionId: selectedOption?.id,
        value: selectedOption?.value,
        colorCode: selectedOption?.colorCode,
      };
      setFieldValue(fieldName, formatted);
    } else {
      const formatted = { optionId: selectedOption?.id, value: selectedOption?.value };
      setFieldValue(fieldName, formatted);
    }
  };

  if (queryLoading)
    return (
      <div>
        <Skeleton variant="text" width={'100%'} height={50} />
        <Skeleton variant="text" width={'100%'} height={50} />
        <Skeleton variant="text" width={'100%'} height={50} />
      </div>
    );

  return (
    <>
      <Stack sx={{ pb: '24px' }}>
        <Stack sx={{ flexDirection: 'row', gap: '8px', pb: '24px' }}>
          <Iconify icon="eva:info-fill" color={'text.disabled'} />
          <Typography variant={'body2'} sx={{ color: 'text.disabled' }}>
            Fields marked with{' '}
            <Box component="span" sx={{ color: 'error.main' }}>
              *
            </Box>{' '}
            are required.
          </Typography>
        </Stack>
        <Stack sx={{ gap: '16px' }}>
          {/*Prosthesis*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Prosthesis
              <Box component="span" sx={{ color: 'error.main' }}>
                *
              </Box>
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
              <TextField
                select={!isEditMode}
                fullWidth
                id="prosthesis"
                name="prosthesis"
                variant="outlined"
                value={isEditMode ? productForEdit?.prosthesis : values?.prosthesis.optionId}
                onChange={(e) => handleFieldChange(e, 'prosthesis', prosthesisOption as Array<ProductOption>)}
                onBlur={handleBlur}
                error={touched.prosthesis && Boolean(errors.prosthesis?.value)}
                helperText={touched.prosthesis?.value && errors.prosthesis?.value}
                disabled={isEditMode}
                size="small"
              >
                {prosthesisOption?.map((item: ProductOption) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="text"
                onClick={() => {
                  if (isEditMode) {
                    return openProsthesisColorDialog();
                  }
                  handleOpenDialogue();
                  setSelectedEditOption('PROSTHESIS');
                }}
                sx={{ height: '40px' }}
              >
                Edit
              </Button>
            </Stack>
          </Stack>

          {/*Method*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Method
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: '8px', height: 'fit-content' }}>
              <TextField
                select={!isEditMode}
                fullWidth
                id="method"
                name="method"
                variant="outlined"
                value={isEditMode ? productForEdit?.method : values?.method.optionId}
                onChange={(e) => handleFieldChange(e, 'method', methodOption as Array<ProductOption>)}
                onBlur={handleBlur}
                error={touched.method && Boolean(errors.method)}
                helperText={touched.method?.value && errors.method?.value}
                disabled={isEditMode}
                size="small"
              >
                {methodOption?.map((item: ProductOption) => (
                  <MenuItem key={item.id} value={item?.id}>
                    {humanizeString(item.value)}
                  </MenuItem>
                ))}
              </TextField>
              {!isEditMode && (
                <Button
                  variant="text"
                  onClick={() => {
                    handleOpenDialogue();
                    setSelectedEditOption('METHOD');
                  }}
                  sx={{ height: '40px' }}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>

          {/*Material*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Material
              <Box component="span" sx={{ color: 'error.main' }}>
                *
              </Box>{' '}
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
              <TextField
                select={!isEditMode}
                fullWidth
                id="material"
                name="material"
                variant="outlined"
                value={isEditMode ? productForEdit?.material : values?.material.optionId}
                onChange={(e) => handleFieldChange(e, 'material', materialOption as Array<ProductOption>)}
                onBlur={handleBlur}
                error={touched.material && Boolean(errors.material)}
                helperText={touched.material?.value && errors.material?.value}
                disabled={isEditMode}
                size="small"
              >
                {materialOption?.map((item: ProductOption) => (
                  <MenuItem key={item.id} value={item?.id}>
                    {humanizeString(item.value)}
                  </MenuItem>
                ))}
              </TextField>
              {!isEditMode && (
                <Button
                  variant="text"
                  onClick={() => {
                    handleOpenDialogue();
                    setSelectedEditOption('MATERIAL');
                  }}
                  sx={{ height: '40px' }}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>

          {/*Shape*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Shape
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: '8px', height: 'fit-content' }}>
              <TextField
                select={!isEditMode}
                fullWidth
                id="shape"
                name="shape"
                variant="outlined"
                value={isEditMode ? productForEdit?.shape : values?.shape.optionId}
                onChange={(e) => handleFieldChange(e, 'shape', shapeOption as Array<ProductOption>)}
                onBlur={handleBlur}
                error={touched.shape?.value && Boolean(errors.shape?.value)}
                helperText={touched.shape?.value && errors.shape?.value}
                disabled={isEditMode}
                sx={{ backgroundColor: isEditMode ? '#919EAB14' : '#fff' }}
                size="small"
              >
                {shapeOption?.map((item: ProductOption) => (
                  <MenuItem key={item.id} value={item.id}>
                    {humanizeString(item.value)}
                  </MenuItem>
                ))}
              </TextField>
              {!isEditMode && (
                <Button
                  variant="text"
                  onClick={() => {
                    handleOpenDialogue();
                    setSelectedEditOption('SHAPE');
                  }}
                  sx={{ height: '40px' }}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>

          {/*Order Range*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Order range
              <Box component="span" sx={{ color: 'error.main' }}>
                *
              </Box>
            </Typography>
            <TextField
              select={!isEditMode}
              fullWidth
              id="designManufactureCombination"
              name="designManufactureCombination"
              variant="outlined"
              value={values?.designManufactureCombination}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.designManufactureCombination && Boolean(errors.designManufactureCombination)}
              helperText={touched.designManufactureCombination && errors.designManufactureCombination}
              disabled={isEditMode}
              size="small"
            >
              {SELECT_DESIGN_MANUFACTURE_COMBINATION_OPTION?.map((item: SelectDto) => {
                return (
                  <MenuItem key={item.id} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Stack>

          {/*Unique price*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Unique price
              <Box component="span" sx={{ color: 'error.main' }}>
                *
              </Box>
            </Typography>
            <TextField
              size="small"
              type="number"
              fullWidth
              id="price"
              name="price"
              variant="outlined"
              value={values?.price}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={false}
              error={touched.price && Boolean(errors.price)}
              helperText={touched.price && errors.price}
            />
          </Stack>

          <Stack sx={{ flexDirection: 'row', gap: '9px', alignItems: 'center', ml: '-12px' }}>
            <Switch
              id={'requireScanBodyAndImplantInfo'}
              name={'requireScanBodyAndImplantInfo'}
              value={values.requireScanBodyAndImplantInfo}
              onChange={handleChange}
            />
            <Typography variant={'body2'} color={'test.primary'} sx={{ ml: '-12px' }}>
              Request for Scanbody and Implant Details
            </Typography>
          </Stack>
        </Stack>

        <EditOptionDialog
          open={open}
          onCloseDialogue={handleCloseDialogue}
          selectedEditOption={selectedEditOption}
          userSelectedEditOptionList={getUserSelectedEditOptionList() as Array<ProductOption>}
          selectedColor={selectedColor}
          onColorChange={handleColorChange}
        />
      </Stack>
      {isEditMode && (
        <ProsthesisColorEditDialog
          open={isProsthesisColorDialogOpen}
          handleClose={closeProsthesisColorDialog}
          productForEdit={productForEdit as ProductDetail}
          onColorChange={handleColorChange}
          defaultColor={productForEdit?.colorCode}
        />
      )}
    </>
  );
};

interface ProsthesisColorEditDialogProps {
  open: boolean;
  handleClose: () => void;
  productForEdit: ProductDetail;
  onColorChange: (color: ColorPair) => void;
  defaultColor: ColorPair;
}

// Prosthesis Color 만 수정하는 Dialog
function ProsthesisColorEditDialog({
  open,
  handleClose,
  productForEdit,
  onColorChange,
  defaultColor,
}: ProsthesisColorEditDialogProps) {
  const [selectedColor, setSelectedColor] = useState<ColorPair>(defaultColor);

  const handleColorSelect = (colorPair: ColorPair) => {
    setSelectedColor(colorPair);
  };

  return (
    <>
      <CustomDialog
        open={open}
        title="Edit prosthesis"
        handleClose={handleClose}
        btnActionText="Confirm"
        btnAction={() => {
          onColorChange(selectedColor);
          handleClose();
        }}
      >
        <Stack gap="24px" sx={{ paddingBottom: '24px' }}>
          <Box>
            <TextField
              disabled
              value={productForEdit.prosthesis}
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
            />
          </Box>

          <ColorSelector selectedColor={selectedColor} onColorChange={handleColorSelect} />
        </Stack>
      </CustomDialog>
    </>
  );
}
