import { atom } from 'recoil';
import { ProductStateDTO } from '../dto/states/productsState.dto';
export const PRODUCT_INITAL_STATE: ProductStateDTO = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  allProductTypes: [],
  allProductMethods: [],
  allProductMaterials: [],
  allProductShapes: [],
  allproductListing: [],
  allProductSupplyList: [],
  supplyListByLab: [],
  productForEdit: {
    product: '',
    method: '',
    material: '',
    shape: '',
    range: '',
    price: '',
    remakePrices: [],
    isDeleted: false,
    currency: '',
    createdAt: '',
    updatedAt: '',
    _id: '',
  },
  productAdditionalOption: {
    hookPrice: 0,
    printing3DmodelPrice: 0,
    finishingPrice: { basic: 0, premium: 0 },
  },
  productRemakePolicy: { remakePolicyText: '' },
};

export const productState = atom({
  key: 'productState',
  default: PRODUCT_INITAL_STATE,
});
