import React from 'react';
import { Button, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';

import { ProductStateDTO } from '../../../recoil/dto/states/productsState.dto';
import { productState } from '../../../recoil/products/productState';
import { useProductActions } from '../../../recoil/products/useProduct';

interface IProductTableToolbarProps {
  onOpenProductCreateDialogue: () => void;
  setOpenAddEditDlg: any;
  productList: any;
}

export const ProductsTableToolbar = ({ onOpenProductCreateDialogue, productList }: IProductTableToolbarProps) => {
  const [productRecoilState, setProductRecoilState] = useRecoilState<ProductStateDTO>(productState);
  const { updateProductById, getAllProductsForListing, productDeleteById, createProduct } = useProductActions();

  return (
    <Stack alignItems="flex-start" justifyContent="center" sx={{ padding: '20px 0 20px 24px' }}>
      <Button variant="outlined" size="medium" onClick={onOpenProductCreateDialogue} sx={{ minWidth: '126px' }}>
        New Product
      </Button>
    </Stack>
  );
};
