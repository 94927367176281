import * as React from 'react';
import { Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ProductDetail } from '../../../client';
import { DataTableWithScroll } from '../../../components/table/DataTableWithScroll';
import { ICloseButtonOnlyDialogueProps } from '../../../constants/helperHooks/useDialogue';
import { capitalizeFirstLetter } from '../../../utils/formatString';
import { convertOrderRangeText } from '../../Order/Helper';

type MovetoPublishAndDraftDialogueProps = ICloseButtonOnlyDialogueProps & {
  onConfirmAction: () => void;
  title: string;
  desc: string;
  checkedItemList: ProductDetail[];
};

const tableColumn = [
  {
    id: 'product',
    label: 'Product',
    alignCol: 'left',
    maxWidth: 200,
  },
  { id: 'method', label: 'Method', alignCol: 'center', maxWidth: 120 },
  {
    id: 'material',
    label: 'Material',
    alignCol: 'center',
    maxWidth: 120,
  },
  { id: 'shape', label: 'Shape', alignCol: 'center', maxWidth: 120 },
  { id: 'range', label: 'Range', alignCol: 'center', maxWidth: 180 },
  { id: 'uniquePrice', label: 'Unique price', alignCol: 'center', maxWidth: 144 },
];

export const ProductPublishAndDraftDialogue = ({
  open,
  onClose,
  onConfirmAction,
  checkedItemList,
  title,
  desc,
}: MovetoPublishAndDraftDialogueProps) => {
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '932px',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack gap="16px" sx={{ height: '402px', width: '100%' }}>
          <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-line' }}>
            {desc}
          </Typography>
          <DataTableWithScroll tableColumn={tableColumn} sx={{ minHeight: '296px !important' }}>
            {checkedItemList?.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    '& td': {
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '22px',
                    },
                  }}
                >
                  <TableCell align="left" sx={{ maxWidth: 200 }}>
                    {capitalizeFirstLetter(row.prosthesis)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 120 }}>
                    {capitalizeFirstLetter(row.method)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 120 }}>
                    {capitalizeFirstLetter(row.material)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 120 }}>
                    {capitalizeFirstLetter(row.shape)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 180 }}>
                    {convertOrderRangeText(row.designManufactureCombination)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 144 }}>
                    {`JPY ${row.price.toLocaleString()}`}
                  </TableCell>
                </TableRow>
              );
            })}
          </DataTableWithScroll>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ border: 'none !important' }}>
        <Button variant="outlined" color="inherit" size="medium" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => {
            onConfirmAction();
            onClose();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
