import React from 'react';
import { useFormik } from 'formik';

import {
  designManufactureCombination,
  OptionValueRequest,
  ProductDetail,
  ProsthesisOptionValueRequest,
} from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useProductActions } from '../../../recoil/products/useProduct';
import { AddProductDialogContent } from './AddProductDialogContent';
import { DEFAULT_COLOR_SET } from './ColorDialog';

const PROSTHESIS_OPTION_VALUE: ProsthesisOptionValueRequest = {
  optionId: '',
  value: '',
  colorCode: DEFAULT_COLOR_SET,
};

const DEFAULT_OPTION_VALUE: OptionValueRequest = {
  optionId: '',
  value: '',
};

export const NEW_PRODUCT_INIT_STATE = {
  price: 0,
  priceUnit: 'JPY',
  prosthesis: PROSTHESIS_OPTION_VALUE,
  method: DEFAULT_OPTION_VALUE,
  material: DEFAULT_OPTION_VALUE,
  shape: DEFAULT_OPTION_VALUE,
  designManufactureCombination: 'DESIGN_AND_MANUFACTURE' as designManufactureCombination,
  // colorCode: DEFAULT_COLOR_SET,
  stroke: '',
  fill: '',
  requireScanBodyAndImplantInfo: false,
};

const transformToFormikForm = (productForEdit: ProductDetail) => {
  const updated = NEW_PRODUCT_INIT_STATE;
  updated.price = productForEdit.price;
  updated.requireScanBodyAndImplantInfo = productForEdit.requireScanBodyAndImplantInfo;
  updated.fill = productForEdit.colorCode.fill;
  updated.stroke = productForEdit.colorCode.stroke;

  return updated;
};

interface IAddProductDialog {
  open: boolean;
  onCloseDialog: () => void;
  btnActionText: string;
  btnAction: () => void;
  productForEdit?: ProductDetail;
}
export default function AddProductDialog({
  open,
  onCloseDialog,
  btnActionText,
  btnAction,
  productForEdit,
}: IAddProductDialog) {
  const { useProductItemMutation, useProductItemEditMutation } = useProductActions();
  const { mutateAsync } = useProductItemMutation();
  const { mutateAsync: productItemEditAsync } = useProductItemEditMutation();

  const isEditMode = !!productForEdit;
  console.log(productForEdit);

  const formik = useFormik({
    initialValues: isEditMode ? transformToFormikForm(productForEdit) : NEW_PRODUCT_INIT_STATE,
    onSubmit: async (values) => {
      if (isEditMode) {
        // PATCH
        const { stroke, fill, requireScanBodyAndImplantInfo, price } = values;
        const request = {
          id: productForEdit.id,
          body: { price, colorCode: { stroke, fill }, requireScanBodyAndImplantInfo },
        };
        await productItemEditAsync(request);
      } else {
        // POST
        await mutateAsync(values);
      }
      formik.resetForm();
      onCloseDialog();
    },
  });
  const { values, handleSubmit } = formik;
  const btnDisabled = isEditMode
    ? values?.price === 0
    : values.prosthesis.value === '' || values.material.value === '' || values.price === 0;

  console.log(values);
  return (
    <form onSubmit={handleSubmit} id="product-creation-form">
      <CustomDialog
        width={'455px'}
        title={isEditMode ? 'Edit product' : 'Add new product'}
        open={open}
        handleClose={() => {
          onCloseDialog();
          formik.resetForm();
        }}
        // dialogActions={false}
        btnActionText={btnActionText}
        btnDisabled={btnDisabled}
        btnType="submit"
        formId={'product-creation-form'}
        // dialogActionsStyle={{ marginLeft: 7, maxWidth: '66%', display: 'flex', justifyContent: 'space-between' }}
      >
        <AddProductDialogContent onClose={onCloseDialog} formik={formik} productForEdit={productForEdit} />
      </CustomDialog>
    </form>
  );
}
