import React, { useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { Button, Stack, TextField, Typography } from '@mui/material';

import { ProductDetail, RemakePrice, RemakePriceRequest, UpdateProductRemakePriceRequest } from '../../../client';
import { LeaveSiteDialogue } from '../../../components/dialog/LeaveSiteDialogue';
import Iconify from '../../../components/iconify/Iconify';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useProductRemakeMutation } from '../../../recoil/remake/useRemake';
import useTopAlert from '../../../recoil/topAlert/useTopAlert';
import RemakeListTable from './RemakeListTable';
import { RemakePolicyDialogue } from './RemakePolicyDialogue';
import { SetupRemakeDialogue } from './SetupRemakeDialogue';
import { UpdateProductLstConfirmDialogue } from './UpdateProductLstConfirmDialogue';

const RemakePage = () => {
  const { setTopAlert, initTopAlert } = useTopAlert();
  const remakePolicyDialogue = useDialogue();
  const setupRemakeDialogue = useDialogue();
  const updateProductListConfirmDialogue = useDialogue();

  const [modifiedProductRemakePrice, setModifiedProductRemakePrice] = useState<UpdateProductRemakePriceRequest>({
    updateAllInfo: [] as Array<RemakePrice>,
    updateSingle: [] as Array<RemakePriceRequest>,
  });
  const [selectedProductForRemake, setSelectedProductForRemake] = useState<RemakePriceRequest>({
    productId: '',
    remakePrice: [],
  });

  const { mutateAsync, isPending } = useProductRemakeMutation();

  const handleProductListUpdate = async () => {
    await mutateAsync(modifiedProductRemakePrice, {
      onSuccess: () => {
        updateProductListConfirmDialogue.handleCloseDialogue();
        setTopAlert({
          open: true,
          variant: 'filled',
          severity: 'success',
          description: 'Remake setup updated successfully',
          onClose: initTopAlert,
        });
        setModifiedProductRemakePrice({
          updateAllInfo: [],
          updateSingle: [],
        });
      },
      onError: () => {
        setTopAlert({
          open: true,
          variant: 'filled',
          severity: 'error',
          description: 'Remake setup failed to update',
          onClose: initTopAlert,
        });
      },
    });
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      (modifiedProductRemakePrice.updateAllInfo.length > 0 || modifiedProductRemakePrice.updateSingle.length > 0) &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Remake</Typography>
      <Stack gap="20px">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {/*<Stack direction="row" alignItems="center">*/}
          {/*  <Box>*/}
          {/*    <img style={{ marginLeft: '30px', width: '18px', height: '18px' }} src={InfoEdit} alt="" />*/}
          {/*  </Box>*/}
          {/*  <Typography*/}
          {/*    onClick={() => setOpenTextEditorDialog(true)}*/}
          {/*    color={'#6A6E74'}*/}
          {/*    ml={1}*/}
          {/*    mb={10}*/}
          {/*    fontSize={14}*/}
          {/*    fontWeight={400}*/}
          {/*    sx={{ textDecoration: 'underline', cusror: 'pointer' }}*/}
          {/*  >*/}
          {/*    Remake Policy*/}
          {/*  </Typography>*/}
          {/*</Stack>*/}
          <Button
            variant="text"
            color="primary"
            size="medium"
            startIcon={<Iconify icon="eva:info-fill" />}
            onClick={remakePolicyDialogue.handleOpenDialogue}
            disabled={true}
          >
            Remake policy
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => {
              setSelectedProductForRemake({ productId: '', remakePrice: [] });
              setupRemakeDialogue.handleOpenDialogue();
            }}
          >
            Setup remake for all
          </Button>
          {/*<RemakeTableToolbar*/}
          {/*  setOpenRemakeSetupDlg={setOpenRemakeSetupDlg}*/}
          {/*  openSetupAllModal={() => {*/}
          {/*    setSelectedRemakeId('');*/}
          {/*    setOpenRemakeSetupDlg(true);*/}
          {/*    setRemakeProductIds([]);*/}
          {/*    // setRemakeProductList([...allproductListing]);*/}
          {/*  }}*/}
          {/*  remakeProductList={[]}*/}
          {/*  // updateAllProductRemakeList={updateAllProductRemakeList}*/}
          {/*/>*/}
        </Stack>
        <RemakeListTable
          modifiedProductRemakePrice={modifiedProductRemakePrice}
          handleOpenSetupRemakeDialogue={(product: ProductDetail) => {
            setSelectedProductForRemake({ productId: product.id, remakePrice: product.remakePrice });
            setupRemakeDialogue.handleOpenDialogue();
          }}
        />
      </Stack>
      <Stack alignItems="flex-end">
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!modifiedProductRemakePrice.updateAllInfo.length && !modifiedProductRemakePrice.updateSingle.length}
          onClick={updateProductListConfirmDialogue.handleOpenDialogue}
          sx={{ maxWidth: '84px' }}
        >
          Update
        </Button>
      </Stack>

      <SetupRemakeDialogue
        open={setupRemakeDialogue.open}
        onClose={setupRemakeDialogue.handleCloseDialogue}
        selectedProductForRemake={selectedProductForRemake}
        modifiedProductRemakePrice={modifiedProductRemakePrice}
        setModifiedProductRemakePrice={setModifiedProductRemakePrice}
      />
      <UpdateProductLstConfirmDialogue
        open={updateProductListConfirmDialogue.open}
        onClose={updateProductListConfirmDialogue.handleCloseDialogue}
        handleProductListUpdate={handleProductListUpdate}
        isPending={isPending}
      />
      <LeaveSiteDialogue blocker={blocker} />
      <RemakePolicyDialogue open={remakePolicyDialogue.open} onClose={remakePolicyDialogue.handleCloseDialogue} />

      {/*<RemakePolicyDlg*/}
      {/*  remakePolicyData={productRemakePolicy}*/}
      {/*  updateProductRemakePolicy={updateProductRemakePolicy}*/}
      {/*  getProductRemakePolicy={getProductRemakePolicy}*/}
      {/*  handleClose={() => {*/}
      {/*    setOpenTextEditorDialog(false);*/}
      {/*  }}*/}
      {/*  open={openTextEditorDialog}*/}
      {/*/>*/}
      <TESTQC />
    </Stack>
  );
};

export default RemakePage;

// TODO: REMOVE
const TESTQC = () => {
  const [email, setEmail] = useState('');
  const [addingRole, setAddingRole] = useState('');
  const [deletingRole, setDeletingRole] = useState('');

  const handleQCClick = () => {
    // todo: async api
    console.log(email);
    console.log(addingRole.split(','));
    console.log(deletingRole.split(','));

    //
    setEmail('');
    setAddingRole('');
    setDeletingRole('');
  };

  if (!process.env.REACT_APP_IS_DEV_ENV || process.env.REACT_APP_IS_DEV_ENV !== 'true') return null;

  return (
    <Stack direction="row">
      <TextField value={email} placeholder="email" onChange={(e) => setEmail(e.target.value)} />
      <TextField value={addingRole} placeholder="adding role" onChange={(e) => setAddingRole(e.target.value)} />
      <TextField value={addingRole} placeholder="deleting role" onChange={(e) => setDeletingRole(e.target.value)} />
      <Button onClick={handleQCClick}>Test QC</Button>
    </Stack>
  );
};
