import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TableCell, TableRow } from '@mui/material';

import { Organization } from '../../client';
import Label from '../../components/label';
import CustomDataTable from '../../components/table/DataTable';
import { TableLoading } from '../../components/table/TableLoading';
import { TableNoData } from '../../components/table/TableNoData';
import { TablePropsReturnType } from '../../components/table/types';
import { APP_ROUTES } from '../../constants/AppRoutes';

const tableColumn = [
  { id: 'type', label: 'Type', maxWidth: 140, alignCol: 'center' },
  {
    id: 'organizationName',
    label: 'Organization Name',
    maxWidth: 300,
    alignCol: 'left',
  },
  { id: 'organizationAddress', label: 'Organization address', maxWidth: 388, alignCol: 'left' },
  {
    id: 'organizationPhone',
    label: 'Organization Phone Number',
    maxWidth: 258,
    alignCol: 'left',
  },
  {
    id: 'corporateNumber',
    label: 'Corporate Number',
    maxWidth: 226,
    alignCol: 'center',
  },
  // {
  //   id: 'noOfOrders',
  //   label: 'Number Of Order',
  //   minWidth: 100,
  //   alignCol: 'center',
  // },
  // { id: 'actions', label: '', minWidth: 100, alignCol: 'center' },
];

type OrganizationListTablePropsType = Partial<TablePropsReturnType> & {
  isPending: boolean;
  organizationList: Array<Organization>;
  totalElement: number;
};

export default function OrganizationListTable(props: OrganizationListTablePropsType) {
  const {
    isPending,
    organizationList,
    onChangePage,
    totalElement,
    onChangeRowsPerPage,
    onChangeDense,
    page,
    dense,
    rowsPerPage,
  } = props;

  const navigate = useNavigate();

  const moveToDetailPage = (id: string) => {
    navigate(`/${APP_ROUTES.ORGANIZATION_DETAIL.ROUTE}/${id}`);
  };

  return (
    <CustomDataTable
      tableColumn={tableColumn}
      rowsPerPage={rowsPerPage}
      page={page}
      totalElement={totalElement}
      dense={dense}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangeDense={onChangeDense}
    >
      {isPending ? (
        <TableLoading style={{ height: '344px' }} colSpan={tableColumn.length} />
      ) : organizationList && organizationList.length ? (
        organizationList.map(({ id, organizationType, name, address, businessPhone, corporateNumber }) => {
          return (
            <TableRow
              key={id}
              onClick={() => moveToDetailPage(id)}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: 'action.hover',
                },
              }}
            >
              <TableCell align="center">
                <Label variant="filled" color={organizationType === 'PARTNER_LAB' ? 'success' : 'info'}>
                  {organizationType === 'PARTNER_LAB' ? 'Partner lab' : 'Normal user'}
                </Label>
              </TableCell>
              <TableCell align="left">{name}</TableCell>
              {/*<TableCell align="center">{email}</TableCell>*/}
              <TableCell align="left">
                <Box component="span" display="block">
                  {`(${address.zipCode})`}
                </Box>
                <Box component="span" display="block">
                  {`${address.fullAddress}`}
                </Box>
                <Box component="span" display="block">
                  {`${address.detailAddress}`}
                </Box>
              </TableCell>
              <TableCell align="left">{`+${businessPhone.countryCode} ${businessPhone.number}`}</TableCell>
              <TableCell align="center">{corporateNumber}</TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableNoData
          style={{ height: '344px' }}
          colSpan={tableColumn.length}
          content={'No organizations available yet'}
        />
      )}
    </CustomDataTable>
  );
}
